<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M28.4515 9.7418C28.3036 9.19215 28.0139 8.691 27.6114 8.28851C27.2089 7.88601 26.7077 7.59629 26.1581 7.44834C24.1339 6.90051 16 6.90051 16 6.90051C16 6.90051 7.86611 6.90051 5.84192 7.44834C5.29227 7.59629 4.79112 7.88601 4.38863 8.28851C3.98613 8.691 3.69641 9.19215 3.54847 9.7418C3.17115 11.8063 2.98774 13.9015 3.00063 16.0001C2.98774 18.0987 3.17115 20.1939 3.54847 22.2583C3.69641 22.808 3.98613 23.3091 4.38863 23.7116C4.79112 24.1141 5.29227 24.4038 5.84192 24.5518C7.86611 25.0996 16 25.0996 16 25.0996C16 25.0996 24.1339 25.0996 26.1581 24.5518C26.7077 24.4038 27.2089 24.1141 27.6114 23.7116C28.0139 23.3091 28.3036 22.808 28.4515 22.2583C28.8288 20.1939 29.0123 18.0987 28.9994 16.0001C29.0123 13.9015 28.8288 11.8063 28.4515 9.7418ZM13.4001 19.8999V12.1003L20.1505 16.0001L13.4001 19.8999Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconYouTube",
});
</script>
